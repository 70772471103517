<template>
  <v-container fluid>
    <!-- Pop-up : Utilisation de cookies -->
    <div>
      <v-bottom-sheet
        v-model="dialogCookies"
        persistent
      >
        <v-sheet
          class="text-center"
          height="200px"
        >
          <v-btn
            class="mt-6"
            flat
            color="green"
            text
            @click="closeCookie"
          >
            Ok, continue
          </v-btn>
          <div class="py-3">
            By continuing on this site you accept the use of time limited session cookies for purposes of authentication <br>
            as well as cookies required for your customized user interface.
          </div>
        </v-sheet>
      </v-bottom-sheet>
    </div>

    <!-- Page login -->
    <v-row
      align="center"
      justify="center"
      class="text-center mx-auto"
    >
      <!-- Images -->
      <v-col
        cols="12"
        class="mx-4 my-4"
      >
        <v-row>
          <v-col cols="3">
            <v-img
              contain
              aspect-ratio="2.5"
              lazy-src="@/assets/machine_froggy.png"
              src="@/assets/machine_froggy.png"
            />
          </v-col>
          <v-col cols="3">
            <v-img
              contain
              aspect-ratio="2.5"
              lazy-src="@/assets/machine_luke.png"
              src="@/assets/machine_luke.png"
            />
          </v-col>
          <v-col cols="3">
            <v-img
              contain
              aspect-ratio="2.5"
              lazy-src="@/assets/machine_dahu.png"
              src="@/assets/machine_dahu.png"
            />
          </v-col>
          <v-col cols="3">
            <v-img
              contain
              aspect-ratio="2.5"
              lazy-src="@/assets/machine_openstack.png"
              src="@/assets/machine_openstack.png"
            />
          </v-col>
        </v-row>
      </v-col>

      <!-- Charte et certificat -->
      <!--
      <v-col
        cols="12"
        class="mx-4 my-4 orange"
      >
        <v-row
          align="center"
          justify="center"
        >
          Get the ciment
          <v-btn
            href="https://ciment.ujf-grenoble.fr/wiki-pub/images/7/79/Charte_CIMENT_Utilisateurs_v1_2013.pdf"
            target="_blank"
            text
            color="primary"
            class="title font-weight-light"
            style="text-transform: none"
          >
            Charter
          </v-btn>
          or
          <v-btn
            href="https://gricad-gitlab.univ-grenoble-alpes.fr/gricad/perseus2/perseus-back/blob/bcffc7a4c83536d23a5b2eef559875e89c7f86d5/cert.pem"
            target="_blank"
            text
            color="primary"
            class="title font-weight-light"
            style="text-transform: none"
          >
            Certificate
          </v-btn>
        </v-row>
      </v-col>

      <v-spacer />
      <v-spacer />
      <v-divider :dark="this.$store.state.darkTheme ? true : false" />
      <v-spacer />
      <v-spacer />
      -->

      <!-- Espace de connexion -->
      <v-col
        cols="12"
        class="mx-4 my-4"
      >
        <!-- Alerte de mauvaise authentification -->
        <v-row
          justify="center"
          align="center"
        >
          <v-alert
            v-if="error"
            type="error"
            text
          >
            Bad authentication
          </v-alert>
        </v-row>
        <!-- Card de login -->
        <v-row
          justify="center"
          align="center"
        >
          <v-card
            :color="this.$store.state.darkTheme ? card_bg_dark : card_bg_light"
            :dark="this.$store.state.darkTheme ? true : false"
            :width="isMobile()?(this.$store.state.windowW):(this.$store.state.windowW/2)"
            max-width="800px"
          >
            <v-card-title class="justify-center">
              Welcome back, please login
            </v-card-title>
            <v-col
              cols="10"
              class="mx-auto my-auto"
            >
              <v-form>
                <!--
                  Pour ajouter la regle n'autorisant pas les caracteres speciaux :
                  :rules="[rules.required, rules.loginValid]"
                -->
                <v-text-field
                  v-model="login"
                  prepend-icon="mdi-account"
                  :rules="[rules.required]"
                  type="text"
                  name="inputLogin"
                  label="Login"
                  value=""
                  class="input-group--focused"
                  @keyup.enter="authenticate(login,password)"
                />
                <v-text-field
                  v-model="password"
                  prepend-icon="mdi-lock"
                  :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                  :rules="[rules.required]"
                  :type="show ? 'text' : 'password'"
                  name="inputPassword"
                  label="Password"
                  value=""
                  class="input-group--focused"
                  @click:append="show = !show"
                  @keyup.enter="authenticate(login,password)"
                />
              </v-form>
            </v-col>
            <v-col class="text-center">
              <v-row>
                <v-btn
                  text
                  :small="isMobile()"
                  color="gmiddleblue"
                  style="text-transform: none"
                  @click="(dialogForgotPassword = !dialogForgotPassword)"
                >
                  Forgot password ?
                </v-btn>

                <v-spacer />

                <v-btn
                  class="gmiddleblue title font-weight-regular mr-2"
                  :small="isMobile()"
                  style="text-transform: none"
                  @click="authenticate(login, password)"
                >
                  Sign in &thinsp;
                  <v-icon>fas fa-sign-in-alt</v-icon>
                </v-btn>
              </v-row>
            </v-col>
          </v-card>
        </v-row>

        <v-row
          align="center"
          justify="center"
        >
          <v-card
            :color="this.$store.state.darkTheme?card_bg_dark:card_bg_light"
            :dark="this.$store.state.darkTheme ? true : false"
            max-width="800px"
            :width="isMobile()?(this.$store.state.windowW):(this.$store.state.windowW/2)"
            class="mt-6"
          >
            <v-card-actions class="justify-center">
              <span class="title font-weight-light">
                Don't have an account yet ?
              </span>
              <v-btn
                to="/create-account/portal"
                text
                color="gmiddleblue"
                class="title font-weight-light lowertext"
              >
                Create an account
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-row>
      </v-col>

      <v-spacer />
      <v-spacer />
      <v-divider :dark="this.$store.state.darkTheme ? true : false" />
      <v-spacer />
      <v-spacer />

      <!-- Info : CGU et qu'est ce que Perseus -->
      <v-col
        cols="12"
        class="mx-4 my-4"
      >
        <v-row
          align="center"
          justify="center"
        >
          By using Perseus NG, you agree with
          &thinsp;
          <a
            href="Perseus_Ng_CGU_Metions_Legal.pdf"
            download
          >
            General term of use
          </a>
          .
        </v-row>
        <v-row
          align="center"
          justify="center"
        >
          <v-btn
            text
            color="gmiddleblue"
            style="text-transform: none"
            @click="dialog=!dialog"
          >
            What is Perseus NG ?
          </v-btn>
        </v-row>
      </v-col>
    </v-row>

    <!-- Boite de dialogue : What is Perseus -->
    <v-dialog
      v-model="dialog"
      scrollable
      max-width="500px"
    >
      <v-card max-height="500px">
        <v-card-title>What is PERSEUS NG ?</v-card-title>
        <v-divider />
        <v-card-text>
          <br>
          <p>
            Perseus is a web portal designed for users and administrators of GRICAD.
          </p>
          <p>
            Its goal is to give a entry point to all users of GRICAD's installations.
          </p>
          <br><br><br>
          <p>
            <v-list style="opacity:0.6">
              It offers many functionnalities such as :
              <v-list-item-title>Account creation and status</v-list-item-title>
              <v-list-item>
                The users can create themselves an account to access to GRICAD and checked their authoritation' status.
              </v-list-item>
              <v-list-item-title>Project management</v-list-item-title>
              <v-list-item>
                To access to the clusters, the users has to joined an active project, or create and manage a "project".
              </v-list-item>
              <v-list-item-title>Project review</v-list-item-title>
              <v-list-item>
                Admin project interact with Gricad reviewers to create a project and verify its validity every year. <br>
                It means that the HPC activity is well known by every person concerned.
              </v-list-item>
              <v-list-item-title>Accounting</v-list-item-title>
              <v-list-item>
                The users can easily access to accounting informations relatives to their activity,
                such as the calculus power used (hours use oar each cluster) by them and also or their project.
              </v-list-item>
              <v-list-item-title>Management of mailing list</v-list-item-title>
              <v-list-item>
                Fast activate / desactivate of diffusion link to HPC.
              </v-list-item>
            </v-list>
          </p>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Boite de dialogue : forgot password -->
    <v-dialog
      v-model="dialogForgotPassword"
      scrollable
      persistent
      max-width="800px"
    >
      <v-card max-height="700px">
        <v-card-title>Forgot password</v-card-title>
        <v-btn
          icon
          absolute
          top
          right
          @click="resetForgotPassword"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <!-- Reponse : success -->
        <v-alert
          v-if="succesForgotPassword"
          type="success"
          class="mx-4"
        >
          You will receive an email with a regenerate password.
          Please change it as soon as possible.
        </v-alert>
        <!-- Reponse : error -->
        <v-alert
          v-if="errorForgotPassword"
          type="error"
          class="mx-4"
        >
          {{ responseServer }}
        </v-alert>
        <v-card-text>
          If you are UGA member, your login and password are given by Agalan. <br>
          If not, please enter your login and e-mail in order to verify your identity. <br>
          Then click on validate.
        </v-card-text>
        <v-card-text>
          <v-form
            ref="formFP"
            v-model="validForgotPassword"
            lazy-validation
          >
            <!-- Login -->
            <v-text-field
              v-model.lazy="loginFP"
              :rules="[rules.loginFP1, rules.loginFP2]"
              hint="Enter your login"
              label="Login"
              required
            />
            <!-- E-mail -->
            <v-text-field
              v-model.lazy="emailFP"
              :rules="[rules.emailFP1, rules.emailFP2]"
              label="E-mail"
              hint="Enter your email adress"
              required
            />
          </v-form>
        </v-card-text>
        <v-card-actions class="my-4 mx-4">
          <v-spacer />
          <v-btn
            :disabled="!validForgotPassword"
            color="primary"
            @click="validateForgotPassword"
          >
            Validate
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Logo Gricad -->
    <v-row :class="isMobile()?'justify-center mb-10':''">
      <v-btn
        text
        color="white"
        x-large
        exact
        :absolute="!isMobile()"
        :bottom="!isMobile()"
        :left="!isMobile()"
        height="70px"
        href="https://gricad.gricad-pages.univ-grenoble-alpes.fr/web/publicweb/index.html"
        target="_blank"
      >
        <v-img
          v-if="this.$store.state.darkTheme"
          src="@/assets/logo_gricad_horizontal_dark.png"
          max-width="230px"
        />
        <v-img
          v-else
          src="@/assets/logo_gricad_horizontal.png"
          max-width="230px"
        />
      </v-btn>
    </v-row>

    <!-- bar de chargement -->
    <v-dialog
      v-model="wait"
      persistent
      width="300"
    >
      <v-card
        color="primary"
        dark
      >
        <v-card-text>
          Please stand by
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-row class="my-4" />
  </v-container>
</template>

<script>
  import axios from 'axios'
  import config from '@/config'
  import resetStore from '../store/resetStore'
  import updateData from '../store/updateData'
  import checkToken from '../store/checkToken'

  // connection.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
  export default {
    data () {
      return {
        card_bg_dark: config.colors.gricad_darkblue_dark1, // config.colors.card_bg_dark,
        card_bg_light: config.colors.card_bg_light,
        blue: config.colors.gricad_oceanblue_light2,
        red: config.colors.gricad_red_dark1,

        dialog: false,

        // Partie : forgot password -------
        dialogForgotPassword: false,
        validForgotPassword: false,
        succesForgotPassword: false,
        errorForgotPassword: false,
        loginFP: null,
        emailFP: null,
        wait: false,
        responseServer: '',
        // Partie : forgot password -------

        dialogCookies: true,
        login: '',
        password: '',
        result: '',
        show: false,
        error: false,
        rules: {
          required: value => !!value || 'Required.',
          // min: v => v.length >= 8 || 'Min 8 characters',
          loginMatch: () => "The login and password you entered don't match",
          loginValid: v => !(v || '').match(/[^a-z0-9]/) || "Your login can't contain special, uppercase or accent characteres",
          loginFP1: v => !!v || 'A login is required',
          loginFP2: v => (v && v.length <= 16) || 'Your login must be less than 16 characters',
          loginFP3: v => !(v || '').match(/[^a-z0-9]/) || "Please, don't use special, uppercase or accent characteres",
          emailFP1: v => !!v || 'E-mail is required',
          emailFP2: v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
        },
      }
    },

    created () {
      var bool = $cookies.get('acceptCookies')
      if (bool) { // TODO plutot 'if bool' tout simplement
        this.dialogCookies = false
      }
      this.error = false
      resetStore()
      $cookies.remove('token')
    },

    methods: {
      isMobile () {
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
          return true
        } else {
          return false
        }
      },

      validateForgotPassword () {
        if (this.$refs.formFP.validate()) {
          this.wait = true
          axios.get('/username/' + this.loginFP + '/forget-password/' + this.emailFP)
            .then(response => {
              this.wait = false
              if (response.data.includes('ERROR')) {
                this.succesForgotPassword = false
                this.errorForgotPassword = true
                this.responseServer = response.data
              } else {
                this.succesForgotPassword = true
              }
            })
        }
      },

      resetForgotPassword () {
        this.succesForgotPassword = false
        this.errorForgotPassword = false
        // this.loginFP = null
        // this.emailFP = null
        this.dialogForgotPassword = false
      },

      closeCookie: function () {
        $cookies.set('acceptCookies', true, '1y')
        this.dialogCookies = false
      },
      authenticate: async function (uid, password) {
        // Normalisation du login pour eviter tout soucis
        var uidNormalized = uid
        uidNormalized = uidNormalized.normalize('NFD').replace(/[\u0300-\u036f]/g, '') // remove accents
        uidNormalized = uidNormalized.toLowerCase() // remove uppercase character
        // Requete de connexion
        this.error = false
        await axios
          .post('/connection', {
            password: password,
            uid: uidNormalized,
          })
          .then(async response => {
            // console.log(response.data)
            if (!response.data.authenticated && !response.data.token) {
              this.error = true
            } else {
              var token = response.data.token
              $cookies.set('token', token)

              var authtoken = response.data.authtoken
              $cookies.set('authtoken', authtoken)

              /* var data = JSON.parse(atob(token.split('.')[1]))
              const exp = new Date(data.exp * 1000) // JS deals with dates in milliseconds since epoch
              const now = new Date()

              axios.get('https://api.ipify.org?format=json')
                .then(response => {
                  this.$store.state.my_ip = response.data.ip
                })
              console.log(store.state.my_ip)
              console.log(window.location.host)
              console.log(data.ip)
              var validToken = now < exp
              store.state.validToken = validToken
              console.log(store.state.validToken) */
              var validToken = checkToken(token)
              if (validToken) {
                this.$store.state.token = token
                this.$store.state.authtoken = authtoken
                updateData()
                // TODO: faire la redirection ici pour l'email key
                if (this.$route.query.redirect) {
                  this.$router.push(this.$route.query.redirect).catch((err) => {
                    throw new Error(`Problem handling something: ${err}.`)
                  })
                } else {
                  this.$router.push('/dashboard').catch((err) => {
                    throw new Error(`Problem handling something: ${err}.`)
                  })
                }
              }
            }
          })
      },
    },
  }
</script>

<style lang="scss">

  .lowertext {
    text-transform: none;
    font-weight: lighter;
  }

</style>
